// Generated by Framer (e9d8252)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["FKGKzuvFh", "WZiJOizii", "qVzIgOOIt", "nYL2SoY9p", "rapM373DF"];

const serializationHash = "framer-K8Qnq"

const variantClassNames = {FKGKzuvFh: "framer-v-1drii1d", nYL2SoY9p: "framer-v-1cb9lnk", qVzIgOOIt: "framer-v-4f9mp7", rapM373DF: "framer-v-15f5mxl", WZiJOizii: "framer-v-1k25a4a"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 1.2, ease: [0, 0, 0, 0], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"1": "WZiJOizii", "2": "qVzIgOOIt", "3": "nYL2SoY9p", "4": "rapM373DF", Start: "FKGKzuvFh"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "FKGKzuvFh"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "FKGKzuvFh", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppearq1slk9 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("WZiJOizii"), 600)
})

const onAppearo93kwc = activeVariantCallback(async (...args) => {
await delay(() => setVariant("qVzIgOOIt"), 1200)
})

const onAppearvo36oa = activeVariantCallback(async (...args) => {
await delay(() => setVariant("nYL2SoY9p"), 1200)
})

const onAppearwrvud = activeVariantCallback(async (...args) => {
await delay(() => setVariant("rapM373DF"), 1200)
})

const onAppear1sogvoy = activeVariantCallback(async (...args) => {
await delay(() => setVariant("FKGKzuvFh"), 600)
})

useOnVariantChange(baseVariant, {default: onAppearq1slk9, nYL2SoY9p: onAppearwrvud, qVzIgOOIt: onAppearvo36oa, rapM373DF: onAppear1sogvoy, WZiJOizii: onAppearo93kwc})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1drii1d", className, classNames)} data-framer-name={"Start"} data-highlight layoutDependency={layoutDependency} layoutId={"FKGKzuvFh"} ref={ref ?? ref1} style={{backgroundColor: "rgb(23, 23, 23)", borderBottomLeftRadius: 11, borderBottomRightRadius: 11, borderTopLeftRadius: 11, borderTopRightRadius: 11, filter: "blur(0px)", WebkitFilter: "blur(0px)", ...style}} {...addPropertyOverrides({nYL2SoY9p: {"data-framer-name": "3"}, qVzIgOOIt: {"data-framer-name": "2"}, rapM373DF: {"data-framer-name": "4"}, WZiJOizii: {"data-framer-name": "1"}}, baseVariant, gestureVariant)}><motion.div className={"framer-11dugo1"} data-framer-name={"Logo Container"} layoutDependency={layoutDependency} layoutId={"D0Oi7sClT"} style={{borderBottomLeftRadius: 11, borderBottomRightRadius: 11, borderTopLeftRadius: 11, borderTopRightRadius: 11}}><motion.div className={"framer-18epjq7"} data-framer-name={"Light"} layoutDependency={layoutDependency} layoutId={"wbC5xtiZC"} style={{backgroundColor: "rgb(255, 255, 255)", filter: "blur(9px)", WebkitFilter: "blur(9px)"}}/><motion.div className={"framer-xd0kl7"} data-framer-name={"Content"} layoutDependency={layoutDependency} layoutId={"AUYEbt4PW"} style={{backgroundColor: "rgb(0, 0, 0)", borderBottomLeftRadius: 9, borderBottomRightRadius: 9, borderTopLeftRadius: 9, borderTopRightRadius: 9}}/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-K8Qnq.framer-g5qm0z, .framer-K8Qnq .framer-g5qm0z { display: block; }", ".framer-K8Qnq.framer-1drii1d { height: 46px; overflow: hidden; position: relative; width: 46px; will-change: var(--framer-will-change-override, transform); }", ".framer-K8Qnq .framer-11dugo1 { bottom: 0px; flex: none; left: 0px; overflow: hidden; position: absolute; right: 0px; top: 0px; will-change: var(--framer-will-change-override, transform); }", ".framer-K8Qnq .framer-18epjq7 { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 15px); left: 0px; overflow: hidden; position: absolute; top: 0px; width: 15px; }", ".framer-K8Qnq .framer-xd0kl7 { flex: none; height: 91%; left: 2px; overflow: visible; position: absolute; top: 2px; width: 91%; }", ".framer-K8Qnq.framer-v-1k25a4a .framer-18epjq7 { left: unset; right: 0px; }", ".framer-K8Qnq.framer-v-4f9mp7 .framer-18epjq7 { bottom: 0px; left: unset; right: 0px; top: unset; }", ".framer-K8Qnq.framer-v-1cb9lnk .framer-18epjq7 { bottom: 0px; top: unset; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 46
 * @framerIntrinsicWidth 46
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"WZiJOizii":{"layout":["fixed","fixed"]},"qVzIgOOIt":{"layout":["fixed","fixed"]},"nYL2SoY9p":{"layout":["fixed","fixed"]},"rapM373DF":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerZhLMnCJse: React.ComponentType<Props> = withCSS(Component, css, "framer-K8Qnq") as typeof Component;
export default FramerZhLMnCJse;

FramerZhLMnCJse.displayName = "Abstract/Border Animation";

FramerZhLMnCJse.defaultProps = {height: 46, width: 46};

addPropertyControls(FramerZhLMnCJse, {variant: {options: ["FKGKzuvFh", "WZiJOizii", "qVzIgOOIt", "nYL2SoY9p", "rapM373DF"], optionTitles: ["Start", "1", "2", "3", "4"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerZhLMnCJse, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})